.light{
    color: #bbb !important;
}

.blue{
    background-color: #0e006b;
}

.green{
    background-color: #0ead00;
}

.yellow{
    background-color: #a1a100;
}

.red{
    background-color: #9c0202;
}

.card{
    background: rgba(250,250,250);
    margin-bottom: 3px;
}