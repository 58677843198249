.rbc-day-bg{
    background-color: white;
}

.rbc-today{
    background-color: #325D88;
    color:white;
}

.rbc-now a {
    color: white;
}
.rbc-off-range-bg{
    background-color: #e6e6e6;
}

.rbc-header{
    background-color: #eee;
}

.rbc-btn-group button{
    background-color: #325D88;
    color: white;
    border: black 1px solid;
}

.rbc-event.rbc-selected{
    background-color: rgb(32, 56, 80);
}

.rbc-event{
    background-color: rgb(32, 56, 80);

}
.rbc-event-content{
    font-size: 0.8em;
}

