.App-bg {
  background: url("/assets/imgs/scania-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  min-height:100vh;
  top: -30px;
  position: relative;
}

.App{
  background: rgba(255,255,255,0.7);
  min-height:100vh;
  top:0px;
}

.nav-tabs .nav-link.active{
  background: rgba(255,255,255,0.6);
}

.nav-tabs{
  font-size: 1em;;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.public-DraftEditor-content {
  min-height: 200px;
}

blockquote{
  display: block;
  padding-block-start: 1em;
  padding-block-end: 1em;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  border-left: 7px solid rgb(221, 221, 221);
  font-style: italic;
}